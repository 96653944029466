.App {
  background-color: rgba(0, 0, 0, 0);
  min-height: 100vh; 
  overflow-y: auto; 
  position: relative; 
}

#tsparticles {
  position: fixed; 
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1; 
}
