.separator {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    opacity: 0.5;
    margin: 0;
}

.contact-section {
    background-color: transparent;
    padding: 3rem;
    color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
}

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
}

.contact-item {
    display: flex;
    align-items: center;
    margin: 1rem 0;
}

.contact-icon {
    font-size: 2.5rem;
    margin-right: 1rem;
}

.contact-info {
    font-size: 1.5rem;
}
