/* src/components/Hero.css */
.hero {
  background-color: rgba(0, 0, 0, 0); /* Dark background color with 50% transparency */
  height: 100vh; /* Ensures the hero section takes full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  position: relative;
  opacity: 0; /* Set initial opacity to 0 for animation */ /* Adds fade-in animation */
}
